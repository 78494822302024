@import "~bootswatch/dist/litera/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/litera/bootswatch";

$navbar-height: 57.5px;

$navbar-background: theme-color('dark');
.navbar {
    @extend .navbar-dark;
    background-color: $navbar-background;
}

@import '../main.scss';
